@import '~antd/dist/antd.less';

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #f3f1f1;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}

.survey-response-div {
  padding: 10px;
  border-radius: 10px;
}

.survey-response-div:nth-child(even) {
  background-color: #fdfdfd;
}

.survey-response-div:nth-child(odd) {
  background-color: #efecec;
}

//SURVEY_COMPLETED: 'rgba(137,236,109,0.65)',
//SURVEY_INIT: 'rgba(227,150,62,0.65)',
//SURVEY_NEEDS_UPDATE: 'rgba(227,62,134,0.65)',

.table-row-init {
  background: rgba(227, 150, 62, 0.4);
}
.table-row-completed {
  background: rgba(137, 236, 109, 0.4);
  cursor: pointer;
}
.table-row-needs-update {
  background: rgba(227, 62, 134, 0.4);
  cursor: pointer;
}

.table-row-alert-to-ra {
  background: rgba(243, 24, 24, 0.8);
  cursor: pointer;
}

.table-row-skipped {
  background: rgba(243, 174, 24, 0.8);
  cursor: pointer;
}

@primary-color: #1DA57A;